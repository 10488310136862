<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { Separator, type SeparatorProps } from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	SeparatorProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<Separator
		v-bind="delegatedProps"
		:class="
			cn(
				'shrink-none bg-gray-100',
				props.orientation === 'vertical' ? 'w-px h-full' : ' w-full',
				props.class,
			)
		"
	/>
</template>
